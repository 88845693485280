




import ProjectValuationMarket from "@/components/project-valuation-market/ProjectValuationMarket.vue";
import Project from "@/entity/Project";
import ProjectsService from "@/services/ProjectsService";
import { Component, Mixins, Prop } from "vue-property-decorator";

@Component({
  components: { ProjectValuationMarket },
})
export default class MarketAnalysisView extends Mixins() {}
